/* eslint-disable @next/next/no-html-link-for-pages */
/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from "react";
import router from "next/router";
import cx from "classnames";
import { InternalLink } from "@pepdirect/ui/InternalLink";
import NewsletterForm from "components/NewsletterForm";
import { LogoStackedSvg } from "components/logos";
import { createDatoClient } from "services/datocms";
import { getSdk, mainFooterQuery } from "services/datocms/generated";
import { SourceType } from "services/graphql/generated";
import st from "./index.module.scss";

const RockstarFooter = () => {
  const [footer, setFooter] = useState<mainFooterQuery["mainFooter"]>(null);

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    getSdk(createDatoClient(router?.isPreview))
      .mainFooter()
      .then((res) => {
        setFooter(res.mainFooter);
      });
  }, []);

  return (
    <footer id="colophon" className={st.siteFooter} role="contentinfo">
      <div className={st.siteFooterWrap}>
        <div className={st.footerContainer}>
          <div className={st.footerMain}>
            <div className={st.footerCol}>
              <div className={st.footerInnerWrap}>
                <div className={st.footerNavWrap}>
                  <div id="nav_menu-2" className={st.widget}>
                    <h2>Rockstar</h2>
                    <nav aria-label="Rockstar">
                      <ul id="menu-rockstar-1">
                        {footer?.rockstar?.map((item) => (
                          <li key={item.title}>
                            <InternalLink href={item.url || ""}>
                              {item.title}
                            </InternalLink>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                  <div id="nav_menu-3" className={st.widget}>
                    <h2 className="widget-title">Info</h2>
                    <nav className={st.menuInfoContainer} aria-label="Info">
                      <ul id="menu-info-1">
                        {footer?.info?.map((item) => (
                          <li key={item.title}>
                            <InternalLink href={item.url || ""}>
                              {item.title}
                            </InternalLink>
                          </li>
                        ))}
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-29">
                          {/* empty div is intentional. ccm script inserts here based on id. */}
                          <div id="teconsent" />
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div
                    id="nav_menu-4"
                    className={cx(st.widget, st.newsletterContainer)}
                  >
                    <NewsletterForm source={SourceType.Footer} />
                  </div>{" "}
                </div>
                <div className={st.footerCopyrightWrap}>
                  <div id="block-7" className={cx(st.widget, st.widget_block)}>
                    <h2>Follow us</h2>
                  </div>
                  <div id="block-8" className={cx(st.widget, st.widget_block)}>
                    <ul
                      className={cx(
                        "wp-container-2",
                        "wp-block-social-links",
                        "is-style-logos-only",
                        st.footerSocial
                      )}
                    >
                      <li
                        className={cx(
                          st.socialLink,
                          st.instagram,
                          "wp-block-social-link"
                        )}
                      >
                        <a
                          href="https://www.instagram.com/rockstarenergy"
                          aria-label="Instagram: https://www.instagram.com/rockstarenergy"
                          rel="noopener nofollow noreferrer"
                          target="_blank"
                          className={"wp-block-social-link-anchor"}
                        >
                          {" "}
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <path d="M12,4.622c2.403,0,2.688,0.009,3.637,0.052c0.877,0.04,1.354,0.187,1.671,0.31c0.42,0.163,0.72,0.358,1.035,0.673 c0.315,0.315,0.51,0.615,0.673,1.035c0.123,0.317,0.27,0.794,0.31,1.671c0.043,0.949,0.052,1.234,0.052,3.637 s-0.009,2.688-0.052,3.637c-0.04,0.877-0.187,1.354-0.31,1.671c-0.163,0.42-0.358,0.72-0.673,1.035 c-0.315,0.315-0.615,0.51-1.035,0.673c-0.317,0.123-0.794,0.27-1.671,0.31c-0.949,0.043-1.233,0.052-3.637,0.052 s-2.688-0.009-3.637-0.052c-0.877-0.04-1.354-0.187-1.671-0.31c-0.42-0.163-0.72-0.358-1.035-0.673 c-0.315-0.315-0.51-0.615-0.673-1.035c-0.123-0.317-0.27-0.794-0.31-1.671C4.631,14.688,4.622,14.403,4.622,12 s0.009-2.688,0.052-3.637c0.04-0.877,0.187-1.354,0.31-1.671c0.163-0.42,0.358-0.72,0.673-1.035 c0.315-0.315,0.615-0.51,1.035-0.673c0.317-0.123,0.794-0.27,1.671-0.31C9.312,4.631,9.597,4.622,12,4.622 M12,3 C9.556,3,9.249,3.01,8.289,3.054C7.331,3.098,6.677,3.25,6.105,3.472C5.513,3.702,5.011,4.01,4.511,4.511 c-0.5,0.5-0.808,1.002-1.038,1.594C3.25,6.677,3.098,7.331,3.054,8.289C3.01,9.249,3,9.556,3,12c0,2.444,0.01,2.751,0.054,3.711 c0.044,0.958,0.196,1.612,0.418,2.185c0.23,0.592,0.538,1.094,1.038,1.594c0.5,0.5,1.002,0.808,1.594,1.038 c0.572,0.222,1.227,0.375,2.185,0.418C9.249,20.99,9.556,21,12,21s2.751-0.01,3.711-0.054c0.958-0.044,1.612-0.196,2.185-0.418 c0.592-0.23,1.094-0.538,1.594-1.038c0.5-0.5,0.808-1.002,1.038-1.594c0.222-0.572,0.375-1.227,0.418-2.185 C20.99,14.751,21,14.444,21,12s-0.01-2.751-0.054-3.711c-0.044-0.958-0.196-1.612-0.418-2.185c-0.23-0.592-0.538-1.094-1.038-1.594 c-0.5-0.5-1.002-0.808-1.594-1.038c-0.572-0.222-1.227-0.375-2.185-0.418C14.751,3.01,14.444,3,12,3L12,3z M12,7.378 c-2.552,0-4.622,2.069-4.622,4.622S9.448,16.622,12,16.622s4.622-2.069,4.622-4.622S14.552,7.378,12,7.378z M12,15 c-1.657,0-3-1.343-3-3s1.343-3,3-3s3,1.343,3,3S13.657,15,12,15z M16.804,6.116c-0.596,0-1.08,0.484-1.08,1.08 s0.484,1.08,1.08,1.08c0.596,0,1.08-0.484,1.08-1.08S17.401,6.116,16.804,6.116z"></path>
                          </svg>
                        </a>
                      </li>

                      <li
                        className={cx(
                          st.socialLink,
                          st.twitter,
                          "wp-block-social-link"
                        )}
                      >
                        <a
                          href="https://twitter.com/rockstarenergy"
                          aria-label="Twitter: https://twitter.com/rockstarenergy"
                          rel="noopener nofollow noreferrer"
                          target="_blank"
                          className="wp-block-social-link-anchor"
                        >
                          {" "}
                          <svg
                            width="1200"
                            height="1227"
                            viewBox="0 0 1200 1227"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
                              fill="white"
                            />
                          </svg>
                        </a>
                      </li>

                      <li
                        className={cx(
                          st.socialLink,
                          st.facebook,
                          "wp-block-social-link"
                        )}
                      >
                        <a
                          href="https://www.facebook.com/rockstar/"
                          aria-label="Facebook: https://www.facebook.com/rockstar/"
                          rel="noopener nofollow noreferrer"
                          target="_blank"
                          className="wp-block-social-link-anchor"
                        >
                          {" "}
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <path d="M12 2C6.5 2 2 6.5 2 12c0 5 3.7 9.1 8.4 9.9v-7H7.9V12h2.5V9.8c0-2.5 1.5-3.9 3.8-3.9 1.1 0 2.2.2 2.2.2v2.5h-1.3c-1.2 0-1.6.8-1.6 1.6V12h2.8l-.4 2.9h-2.3v7C18.3 21.1 22 17 22 12c0-5.5-4.5-10-10-10z"></path>
                          </svg>
                        </a>
                      </li>

                      <li
                        className={cx(
                          st.socialLink,
                          st.youtube,
                          "wp-block-social-link"
                        )}
                      >
                        <a
                          href="https://www.youtube.com/rockstarenergy"
                          aria-label="YouTube: https://www.youtube.com/rockstarenergy"
                          rel="noopener nofollow noreferrer"
                          target="_blank"
                          className="wp-block-social-link-anchor"
                        >
                          {" "}
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <path d="M21.8,8.001c0,0-0.195-1.378-0.795-1.985c-0.76-0.797-1.613-0.801-2.004-0.847c-2.799-0.202-6.997-0.202-6.997-0.202 h-0.009c0,0-4.198,0-6.997,0.202C4.608,5.216,3.756,5.22,2.995,6.016C2.395,6.623,2.2,8.001,2.2,8.001S2,9.62,2,11.238v1.517 c0,1.618,0.2,3.237,0.2,3.237s0.195,1.378,0.795,1.985c0.761,0.797,1.76,0.771,2.205,0.855c1.6,0.153,6.8,0.201,6.8,0.201 s4.203-0.006,7.001-0.209c0.391-0.047,1.243-0.051,2.004-0.847c0.6-0.607,0.795-1.985,0.795-1.985s0.2-1.618,0.2-3.237v-1.517 C22,9.62,21.8,8.001,21.8,8.001z M9.935,14.594l-0.001-5.62l5.404,2.82L9.935,14.594z"></path>
                          </svg>
                        </a>
                      </li>

                      <li
                        className={cx(
                          st.socialLink,
                          st.tiktok,
                          "wp-block-social-link"
                        )}
                      >
                        <a
                          href="https://www.tiktok.com/@rockstarenergy"
                          aria-label="TikTok: https://www.tiktok.com/@rockstarenergy"
                          rel="noopener nofollow noreferrer"
                          target="_blank"
                          className="wp-block-social-link-anchor"
                        >
                          {" "}
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 32 32"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            role="img"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <path d="M16.708 0.027c1.745-0.027 3.48-0.011 5.213-0.027 0.105 2.041 0.839 4.12 2.333 5.563 1.491 1.479 3.6 2.156 5.652 2.385v5.369c-1.923-0.063-3.855-0.463-5.6-1.291-0.76-0.344-1.468-0.787-2.161-1.24-0.009 3.896 0.016 7.787-0.025 11.667-0.104 1.864-0.719 3.719-1.803 5.255-1.744 2.557-4.771 4.224-7.88 4.276-1.907 0.109-3.812-0.411-5.437-1.369-2.693-1.588-4.588-4.495-4.864-7.615-0.032-0.667-0.043-1.333-0.016-1.984 0.24-2.537 1.495-4.964 3.443-6.615 2.208-1.923 5.301-2.839 8.197-2.297 0.027 1.975-0.052 3.948-0.052 5.923-1.323-0.428-2.869-0.308-4.025 0.495-0.844 0.547-1.485 1.385-1.819 2.333-0.276 0.676-0.197 1.427-0.181 2.145 0.317 2.188 2.421 4.027 4.667 3.828 1.489-0.016 2.916-0.88 3.692-2.145 0.251-0.443 0.532-0.896 0.547-1.417 0.131-2.385 0.079-4.76 0.095-7.145 0.011-5.375-0.016-10.735 0.025-16.093z" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>{" "}
                  <div className={cx(st.footerCopyright, st.desktop)}>
                    <div
                      id="block-28"
                      className={cx(st.widget, st.widget_block)}
                    >
                      <p>Copyright © {currentYear} Pepsi Energy Co.</p>
                      <p>
                        For information on energy drinks visit&nbsp;
                        <a
                          href="https://www.energydrinkinformation.com/"
                          target="none"
                          title=""
                        >
                          energydrinkinformation.com
                        </a>
                      </p>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className={st.footerCol}>
              <div className={st.footerInnerWrap}>
                <div className={cx(st.footerCopyright, st.small)}>
                  <div id="block-28" className={cx(st.widget, st.widget_block)}>
                    <p>Copyright © {currentYear} Pepsi Energy Co.</p>
                    <p>
                      For information on energy drinks visit&nbsp;
                      <a
                        href="https://www.energydrinkinformation.com/"
                        target="none"
                        title=""
                      >
                        energydrinkinformation.com
                      </a>
                    </p>
                  </div>{" "}
                </div>
              </div>
            </div>
            <div className={st.footerCol}>
              <div className={st.footerInnerWrap}>
                <div
                  id="block-14"
                  className={cx(
                    st.widget,
                    st.widget_block,
                    st.widget_media_image
                  )}
                >
                  <figure className="size-full">
                    <LogoStackedSvg />
                  </figure>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default RockstarFooter;
