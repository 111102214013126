import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { createDatoClient } from "services/datocms";
import { CTAQuery, getSdk } from "services/datocms/generated";

export default function TopCtaBanner(): JSX.Element {
  // setting this initial value to avoid layout shifts
  const [cta, setCta] = useState<CTAQuery["cta"]>({
    title: "TRY NEW ROCKSTAR UNPLUGGED",
    redirectUrl: "/products/3-flavor-variety-pack/",
  });
  const [loading, setLoading] = useState(true);
  const router = useRouter();

  useEffect(() => {
    getSdk(createDatoClient(router?.isPreview))
      .CTA()
      .then((res) => {
        setCta(res.cta);
      })
      .catch(() =>
        setCta({
          title: "TRY NEW ROCKSTAR UNPLUGGED",
          redirectUrl: "/products/3-flavor-variety-pack/",
        })
      )
      .finally(() => {
        setLoading(false);
      });
  }, [router?.isPreview]);

  return (
    <div className="site-promo">
      <div id="block-25" className="widget widget_block widget_text">
        {/* hiding to avoid text change */}
        <p style={{ visibility: loading ? "hidden" : "visible" }}>
          <a href={cta?.redirectUrl || ""} data-type="products">
            {cta?.title}
          </a>
        </p>
      </div>
    </div>
  );
}
